import type { RouterConfig } from '@nuxt/schema';

export default <RouterConfig> {
  routes: _routes => [
    {
      name: 'home',
      path: '/:orderId(\\d+)?',
      component: () => import('~/pages/orders/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
        name: 'Orders',
        key: 'orders',
      },
    },

    {
      name: 'billing-update-methods',
      path: '/billing/update-methods',
      component: () => import('~/pages/billing/update-methods/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
      },
    },

    {
      name: 'products',
      path: '/products',
      component: () => import('@/pages/products/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
      },
    },

    {
      name: 'product',
      path: '/products/:id',
      component: () => import('@/pages/product/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
      },
    },

    {
      name: 'public-products',
      path: '/public/products',
      component: () => import('@/pages/products/page.vue'),
      meta: {
        layout: 'unauthenticated',
      },
    },

    {
      name: 'public-product',
      path: '/public/products/:id',
      component: () => import('@/pages/product/page.vue'),
      meta: {
        layout: 'unauthenticated',
      },
    },

    {
      name: 'registration',
      path: '/register',
      component: () => import('@/pages/registration/page.vue'),
      meta: {
        middleware: 'unauthenticated',
        layout: 'unauthenticated',
      },
    },

    {
      name: 'login',
      path: '/login',
      component: () => import('@/pages/login/page.vue'),
      meta: {
        middleware: 'unauthenticated',
        layout: 'unauthenticated',
      },
    },

    {
      name: 'terms-and-conditions',
      path: '/terms-and-conditions',
      component: () => import('@/pages/terms-and-conditions/page.vue'),
      meta: {
        middleware: 'unauthenticated',
        layout: 'unauthenticated',
      },
    },

    {
      name: 'reset-password',
      path: '/reset-password',
      component: () => import('@/pages/reset-password/page.vue'),
      meta: {
        middleware: 'unauthenticated',
        layout: 'unauthenticated',
      },
    },

    // Admin Routes

    {
      name: 'admin:products',
      path: '/admin/products',
      component: () => import('@/pages/admin/products/page.vue'),
      meta: {
        middleware: ['auth', 'is-admin'],
        layout: 'authenticated',
      },
    },

    {
      name: 'admin:users',
      path: '/admin/clients',
      component: () => import('@/pages/admin/users/page.vue'),
      meta: {
        middleware: ['auth', 'is-admin'],
        layout: 'authenticated',
      },
    },

    // Settings Routes

    {
      name: 'settings:profile',
      path: '/settings/profile',
      component: () => import('@/pages/settings/profile/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
      },
    },
    {
      name: 'stores',
      path: '/stores',
      component: () => import('~/pages/stores/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
      },
    },
    {
      name: 'settings:store:etsy-connect',
      path: '/settings/store/etsy-connect',
      component: () => import('~/pages/stores/page.vue'),
      meta: {
        middleware: 'auth',
        layout: 'authenticated',
      },
    },

    // Not found

    {
      path: '/:pathMatch(.*)*', // Catch-all route
      name: 'not-found',
      component: () => import('@/pages/not-found/page.vue'),
      meta: {
        layout: 'unauthenticated',
      },
    },
  ],
} satisfies RouterConfig;
