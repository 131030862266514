import revive_payload_client_HLgjUcd0l0 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_dyeamsazb5ivfgc754ua5iyqga/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_R5zGG2NsjO from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_dyeamsazb5ivfgc754ua5iyqga/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FFxVILNjUO from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_dyeamsazb5ivfgc754ua5iyqga/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_G7XyORmHOz from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_dyeamsazb5ivfgc754ua5iyqga/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1png54zJmy from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_dyeamsazb5ivfgc754ua5iyqga/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_mXyDxAVyqP from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_dyeamsazb5ivfgc754ua5iyqga/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0ZqSlcTOnF from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_dyeamsazb5ivfgc754ua5iyqga/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mvj85xSm4B from "/app/apps/nuxt-front/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/nuxt-front/.nuxt/components.plugin.mjs";
import prefetch_client_avHwy0SpdB from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_dyeamsazb5ivfgc754ua5iyqga/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/app/apps/nuxt-front/.nuxt/primevue-plugin.mjs";
import plugin_client_aNbeH9vNFr from "/app/apps/nuxt-front/node_modules/.pnpm/@primevue+nuxt-module@4.2.5_@babel+parser@7.27.0_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_i3LP2ZEA20 from "/app/apps/nuxt-front/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@5.4.15_@types+node@22.13.13_sass@1.86.0_terser@5.39.0___fvaulaipxjekvkg5mhpvok2xbq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/app/apps/nuxt-front/.nuxt/unocss.mjs";
import maska_UHaKf2z1iQ from "/app/apps/nuxt-front/plugins/maska.ts";
import google_recaptcha_rYK9Kziv68 from "/app/apps/nuxt-front/plugins/google-recaptcha.ts";
export default [
  revive_payload_client_HLgjUcd0l0,
  unhead_R5zGG2NsjO,
  router_FFxVILNjUO,
  payload_client_G7XyORmHOz,
  navigation_repaint_client_1png54zJmy,
  check_outdated_build_client_mXyDxAVyqP,
  chunk_reload_client_0ZqSlcTOnF,
  plugin_vue3_mvj85xSm4B,
  components_plugin_KR1HBZs4kY,
  prefetch_client_avHwy0SpdB,
  primevue_plugin_egKpok8Auk,
  plugin_client_aNbeH9vNFr,
  plugin_i3LP2ZEA20,
  unocss_MzCDxu9LMj,
  maska_UHaKf2z1iQ,
  google_recaptcha_rYK9Kziv68
]