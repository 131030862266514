import routerOptions0 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_dyeamsazb5ivfgc754ua5iyqga/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/apps/nuxt-front/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}