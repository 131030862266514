<script setup lang="ts">
</script>

<template>
  <Head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin>
    <link href="https://fonts.googleapis.com/css2?family=Figtree:wght@300..900&display=swap" rel="stylesheet">

    <link rel="icon" href="/favicon.ico">

    <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32px.png">
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16px.png">
    <link rel="manifest" href="/site-webmanifest.json">
  </Head>

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
body {
  @apply font-sans color-text-regular;
}

*:focus {
  @apply outline-themed;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
</style>
